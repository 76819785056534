<template>
  <div class="lobby pt-5">
    <img src="@/assets/img/lobby-14-may.png" class="bgimg" usemap="#lobby" />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block lityLink"
      id="cursor_messagewall"
      @click="reroute('/messagewall')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block lityLink"
      id="cursor_america_aud"
      @click="reroute('/americas-ex-us')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block lityLink"
      id="cursor_apac_aud"
      @click="reroute('/apac')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block lityLink"
      id="cursor_emea_aud"
      @click="reroute('/emea')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_aa"
      @click="openContentModal('aa')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_ad"
      @click="openContentModal('ad')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_aspire"
      @click="openContentModal('aspire')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_bold"
      @click="openContentModal('bold')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_next"
      @click="openContentModal('next')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_pride"
      @click="openContentModal('pride')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_sage"
      @click="openContentModal('sage')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_vets"
      @click="openContentModal('vets')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_wfn"
      @click="openContentModal('wfn')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_wotm"
      @click="openContentModal('wotm')"
    />
    <img
      src="@/assets/img/click.png"
      class="clickImage d-none d-lg-block"
      id="cursor_out"
      @click="
        openURL(
          'https://sites.jpmchase.com/sites/todayjpmc/Pages/index.aspx#/profile/7807https://sites.jpmchase.com/sites/cohohome/SitePages/AboutUs/DEI/9ub5n9e8hik.aspx'
        )
      "
    />
    <div id="thelma" class="bg-dark text-white px-1 py-0 border rounded">
      Thelma Ferguson <br />
      Global Head of DEI and Vice Chair, Commercial Banking
    </div>
    <div id="karla" class="bg-dark text-white px-1 py-0 border rounded">
      Karla Arnaiz <br />
      Americas ex U.S. Talent Diversity Equity and Inclusion & Mexico HR
    </div>
    <div id="rachael" class="bg-dark text-white px-1 py-0 border rounded">
      Rachael Orleans <br />
      Head of Talent and DEI, Asia Pacific
    </div>
    <div id="cecil" class="bg-dark text-white px-1 py-0 border rounded">
      Cecil Peters <br />
      EMEA Head of Diversity, Equity and Inclusion
    </div>
    <map name="lobby" id="image-map">
      <area
        alt="Americas ex U.S."
        title="Americas ex U.S."
        coords="323,1173,699,1193,724,1293,313,1278"
        shape="poly"
        @click="reroute('/americas-ex-us')"
      />
      <area
        alt="APAC"
        title="APAC"
        coords="368,1318,363,1413,649,1438,664,1323"
        shape="poly"
        @click="reroute('/apac')"
      />
      <area
        alt="EMEA"
        title="EMEA"
        coords="388,1453,644,1463,629,1554,383,1559"
        shape="poly"
        @click="reroute('/emea')"
      />
      <!-- Person Images -->
      <area
        alt="Thelma Ferguson"
        coords="1450,256,1766,271,1751,767,1430,747"
        shape="poly"
        @touchstart="showToolTip(1)"
        @touchend="hideToolTip(1)"
        @mouseover="showToolTip(1)"
        @mousedown="showToolTip(1)"
        @mouseleave="hideToolTip(1)"
      />
      <area
        alt="Karla Arnaiz"
        coords="1861,291,1871,792,2131,822,2156,321"
        shape="poly"
        @touchstart="showToolTip(2)"
        @touchend="hideToolTip(2)"
        @mouseover="showToolTip(2)"
        @mousedown="showToolTip(2)"
        @mouseleave="hideToolTip(2)"
      />
      <area
        alt="Rachael Orleans"
        coords="2277,351,2256,817,2512,837,2532,386"
        shape="poly"
        @touchstart="showToolTip(3)"
        @touchend="hideToolTip(3)"
        @mouseover="showToolTip(3)"
        @mousedown="showToolTip(3)"
        @mouseleave="hideToolTip(3)"
      />
      <area
        alt="Cecil Peters"
        coords="2617,416,2617,852,2858,877,2878,426"
        shape="poly"
        @touchstart="showToolTip(4)"
        @touchend="hideToolTip(4)"
        @mouseover="showToolTip(4)"
        @mousedown="showToolTip(4)"
        @mouseleave="hideToolTip(4)"
      />
      <!-- /Person Images -->
      <area
        alt="Message"
        title="Message"
        coords="3403,381,3418,1007,4901,912,4861,120"
        shape="poly"
        @click="reroute('/messagewall')"
      />

      <area
        alt="Access Ability"
        title="Access Ability"
        coords="1465,1293,1460,1614,1831,1619,1841,1313"
        shape="poly"
        @click="openContentModal('aa')"
      />
      <area
        alt="Adelante"
        title="Adelante"
        coords="2021,1303,2026,1609,2357,1614,2367,1328"
        shape="poly"
        @click="openContentModal('ad')"
      />
      <area
        alt="AsPIRE"
        title="AsPIRE"
        coords="2527,1338,2827,1333,2848,1619,2542,1619"
        shape="poly"
        @click="openContentModal('aspire')"
      />
      <area
        alt="BOLD"
        title="BOLD"
        coords="2988,1348,3298,1338,3313,1609,2988,1614"
        shape="poly"
        @click="openContentModal('bold')"
      />
      <area
        alt="NextGen"
        title="NextGen"
        coords="3469,1328,3814,1308,3809,1629,3459,1634"
        shape="poly"
        @click="openContentModal('next')"
      />
      <area
        alt="Pride"
        title="Pride"
        coords="4050,1318,4050,1614,4435,1644,4435,1293"
        shape="poly"
        @click="openContentModal('pride')"
      />

      <area
        alt="Sage"
        title="Sage"
        coords="3058,2050,3053,2315,3353,2365,3373,2060"
        shape="poly"
        @click="openContentModal('sage')"
      />
      <area
        alt="Vets"
        title="Vets"
        coords="3529,2060,3524,2411,3859,2431,3884,2035"
        shape="poly"
        @click="openContentModal('vets')"
      />
      <area
        alt="WFN"
        title="WFN"
        coords="4060,2120,4085,2461,4490,2506,4475,2120"
        shape="poly"
        @click="openContentModal('wfn')"
      />
      <area
        alt="Women on the Move"
        title="Women on the Move"
        coords="4741,2145,5312,2160,5312,2596,4696,2531"
        shape="poly"
        @click="openContentModal('wotm')"
      />
    </map>

    <iframe
      class="lity-hide"
      id="link_photoBooth"
      frameborder="0"
      style="width: 100%; height: 100vh; border: none"
      src="https://klobbi.vbth.app/JPM"
      allow="camera; autoplay; encrypted-media;"
    >
    </iframe>
    <!-- auditorium closed modal -->
    <div
      class="modal fade"
      id="closedAuditorium"
      tabindex="-1"
      aria-labelledby="closedAuditoriumLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  /></svg
              ></span>
            </button>
            <h4 class="text-center" v-if="!selected_booth">
              {{ settings.closed_aud_message }}
            </h4>
          </div>
        </div>
      </div>
    </div>

    <!-- organizers modal -->
    <div
      class="modal fade"
      id="organizersModal"
      tabindex="-1"
      aria-labelledby="organizersModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  /></svg
              ></span>
            </button>
            <div class="row justify-content-center">
              <div class="col-sm-12 mx-auto">
                <!-- <img
                  :src="require(`../${content[selected_booth].logo}`)"
                  class="img-fluid"
                  alt="logo"
                  v-if="selected_booth !== ''"
                /> -->
              </div>
            </div>

            <h3 class="text-center mt-5" v-if="!selected_booth">
              Coming Soon!
            </h3>
          </div>
        </div>
      </div>
    </div>
    <!-- organizers content modal -->
    <div
      class="modal fade"
      id="contentModal"
      tabindex="-1"
      aria-labelledby="contentModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl"
      >
        <div class="modal-content" v-if="selected_booth == 'aa'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/aa.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_tka2nyt3'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/Access Ability/v-aa.PNG"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR Video</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openPdf(
                      '/booths/Access Ability/BRG Virtual Booth - Access Ability.pdf'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/Access Ability/aa.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR PDF</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content" v-if="selected_booth == 'ad'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/ad.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_4jx29t10'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img src="/booths/ad/video.PNG" class="img-fluid" alt="" />
                  </div>
                  <p>CLICK HERE FOR Video</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openPdf('/booths/ad/BRG Virtual Booth - Adelante.pdf')
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img src="/booths/ad/ad.jpg" class="img-fluid" alt="" />
                  </div>
                  <p>CLICK HERE FOR PDF</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="selected_booth == 'aspire'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/asp.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_0kcza1ju'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/aspire/video.PNG"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR Video</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openPdf('/booths/aspire/BRG Virtual Booth - AsPIRE.pdf')
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/aspire/aspire.jpg"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR PDF</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="selected_booth == 'bold'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/bol.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center"
                @click="
                  openVideo(
                    'https://videocenter.jpmcvideo.com/media/t/1_i8y7hrsi'
                  )
                "
              >
                <div class="video bg-primary mb-2">
                  <img src="/booths/BOLD/video.PNG" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR VIDEO</p>
              </div>

              <div
                class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center"
                @click="openPdf('/booths/BOLD/BRG Virtual Booth - BOLD.pdf')"
              >
                <div class="video bg-primary mb-2">
                  <img src="/booths/BOLD/bold.jpg" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR PDF</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content" v-if="selected_booth == 'next'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/next.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div
                class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center"
                @click="
                  openVideo(
                    'https://videocenter.jpmcvideo.com/media/t/1_7yp9szxj'
                  )
                "
              >
                <div class="video bg-primary mb-2">
                  <img
                    src="/booths/NextGen/video.PNG"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <p>CLICK HERE FOR VIDEO</p>
              </div>

              <div
                class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center"
                @click="
                  openPdf('/booths/NextGen/BRG Virtual Booth - NextGen.pdf')
                "
              >
                <div class="video bg-primary mb-2">
                  <img
                    src="/booths/NextGen/next.jpg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
                <p>CLICK HERE FOR PDF</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="selected_booth == 'pride'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/pride_logo.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_5aejokny'
                    )
                  "
                >
                  <img src="/booths/PRIDE/video.PNG" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR VIDEO</p>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openPdf('/booths/PRIDE/BRG Virtual Booth - PRIDE.pdf')
                  "
                >
                  <img src="/booths/PRIDE/pride.jpg" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR PDF</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content" v-if="selected_booth == 'sage'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/sage_logo.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_6u75z8c3'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/Sage/video.PNG"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR VIDEO</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="openPdf('/booths/Sage/BRG Virtual Booth - Sage.pdf')"
                >
                  <div class="pdf bg-primary mb-2">
                    <img src="/booths/Sage/sage.jpg" class="img-fluid" alt="" />
                  </div>
                  <p>CLICK HERE FOR PDF</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content" v-if="selected_booth == 'vets'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/vets_logo.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_58mnkblw'
                    )
                  "
                >
                  <div class="pdf bg-primary mb-2">
                    <img
                      src="/booths/VETS/video.PNG"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p>CLICK HERE FOR VIDEO</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  @click="openPdf('/booths/VETS/BRG Virtual Booth - VETS.pdf')"
                >
                  <div class="pdf bg-primary mb-2">
                    <img src="/booths/VETS/vets.jpg" class="img-fluid" alt="" />
                  </div>
                  <p>CLICK HERE FOR PDF</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content" v-if="selected_booth == 'wfn'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <img
                  :src="require(`../assets/img/logos/wfn_logo.png`)"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_ab9xzrsr'
                    )
                  "
                >
                  <img src="/booths/WFN/video.PNG" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR VIDEO</p>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openPdf(
                      '/booths/WFN/2024 BRG One-pagers - WFN v2_F.pdf'
                    )
                  "
                >
                  <img src="/booths/WFN/wfn.jpg" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR PDF</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-content" v-if="selected_booth == 'wotm'">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  />
                </svg>
              </span>
            </button>
            <div class="row">
              <div class="col-sm-6 ml-auto">
                <button
                  class="btn btn-primary btn-lg float-right"
                  @click="signupRed"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-sm-6 mx-auto">
                <a
                  href="https://farm9.staticflickr.com/8642/16455005578_0fdfc6c3da_b.jpg"
                  data-lity
                  data-lity-desc="Photo of a flower"
                >
                  <img
                    :src="require(`../assets/img/logos/women.png`)"
                    class="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openVideo(
                      'https://videocenter.jpmcvideo.com/media/t/1_c7owodjh'
                    )
                  "
                >
                  <img src="/booths/WOTM/video.PNG" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR VIDEO</p>
              </div>

              <div class="col-lg-4 col-md-6 col-12 h-100 mb-3 text-center">
                <div
                  class="pdf bg-primary mb-2"
                  @click="
                    openPdf(
                      '/booths/WOTM/BRG Virtual Booth - Women on the Move.pdf'
                    )
                  "
                >
                  <img src="/booths/WOTM/wotm.jpg" class="img-fluid" alt="" />
                </div>
                <p>CLICK HERE FOR PDF</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="contactModal"
      tabindex="-1"
      aria-labelledby="contactModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  /></svg
              ></span>
            </button>

            <h5 class="text-uppercase text-center mt-4">
              Thank You for your interest
            </h5>

            <div class="form-group">
              <label> SID</label>
              <input
                type="text"
                name="sid"
                placeholder="SID"
                v-model="sid"
                class="form-control"
              />
              <span
                class="text-warning small"
                v-for="(message, mindex) of validation_message.sid"
                :key="mindex"
                >{{ message }}<br
              /></span>
            </div>

            <div class="form-group">
              <label> Email</label>
              <input
                type="email"
                name="email"
                placeholder="Email"
                v-model="email"
                class="form-control"
              />
              <span
                class="text-warning small"
                v-for="(message, mind) of validation_message.email"
                :key="mind"
                >{{ message }}<br
              /></span>
            </div>
            <div class="d-flex justify-content-center">
              <button class="btn btn-primary" @click="storeContactRequest">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="AccessCodeModal"
      tabindex="-1"
      aria-labelledby="AccessCodeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-x-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"
                  /></svg
              ></span>
            </button>

            <div class="form-group">
              <label>Access Code</label>
              <input
                type="text"
                name="sid"
                placeholder="Access Code"
                v-model="accessCode"
                class="form-control"
              />
              <span
                class="text-warning small"
                v-for="(message, aindex) of valid_accessCode"
                :key="aindex"
                >{{ message }}<br
              /></span>
            </div>

            <div class="d-flex justify-content-center">
              <button class="btn btn-primary" @click="checkAccessCode">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ImageMap from "image-map";
import axios from "axios";
import lity from "lity";
import util from "@/assets/js/util";
import Api from "@/assets/js/Api";
import { mapState } from "vuex";

export default {
  name: "lobby",
  computed: mapState(["settings"]),

  data() {
    return {
      validation_message: {
        email: [],
        sid: [],
      },
      valid_accessCode: [],
      accessCode: "",
      email: "",
      sid: "",
      attendance_interval: false,

      selected_booth: "",
      content: {
        aa: {
          name: "Access Ability",
          logo: "assets/img/logos/AccessAbility.jpg",
          video: {
            src: "587707346?h=c8bd05f9c8",
            label: "It's Easy",
          },
          pdf: {
            thumbnail: "assets/img/content/AccessAbility/pdfthumbnail.png",
            label: "Stories",
          },
          quiz: {
            thumbnail:
              "assets/img/content/AccessAbility/3OurPastEventHighlights.png",
            link: "https://google.com",
            label: "Win Prize",
          },
        },
      },
    };
  },
  methods: {
    showToolTip(type) {
      switch (type) {
        case 1:
          $("#thelma").css("display", "block");
          $("#karla").css("display", "none");
          $("#rachael").css("display", "none");
          $("#cecil").css("display", "none");

          break;
        case 2:
          $("#karla").css("display", "block");
          $("#thelma").css("display", "none");
          $("#rachael").css("display", "none");
          $("#cecil").css("display", "none");
          break;
        case 3:
          $("#rachael").css("display", "block");
          $("#thelma").css("display", "none");
          $("#karla").css("display", "none");
          $("#cecil").css("display", "none");
          break;
        case 4:
          $("#cecil").css("display", "block");
          $("#rachael").css("display", "none");
          $("#thelma").css("display", "none");
          $("#karla").css("display", "none");
          break;
      }
    },
    hideToolTip(type) {
      switch (type) {
        case 1:
          $("#thelma").css("display", "none");
          break;
        case 2:
          $("#karla").css("display", "none");
          break;
        case 3:
          $("#rachael").css("display", "none");
          break;
        case 4:
          $("#cecil").css("display", "none");
          break;
      }
    },

    async checkAccessCode() {
      if (this.accessCode !== "DEIWEEK2024") {
        this.valid_accessCode = ["Invalid Access Code."];
      } else {
        localStorage.setItem("accesscode", this.accessCode);
        this.valid_accessCode = [];
        $("#AccessCodeModal").modal("hide");
      }
    },
    signupRed() {
      window.open(
        "https://hrsp19.jpmchase.net/sites/brg/SitePages/Home.aspx",
        "_blank"
      );
    },
    resetValidation() {
      this.validation_message = {
        email: [],
        sid: [],
      };
    },
    storeContactRequest() {
      Api.post("contact-request", {
        sid: this.sid,
        email: this.email,
        meta: this.selected_booth,
      }).then((res) => {
        if (res.data.validation_error) {
          this.validation_message = res.data.validation_message;
          return;
        }
        this.resetValidation();
        $("#contactModal").modal("hide");
        this.$toast.open({
          message: "Submitted Successfully!",
          type: "success",
        });
      });
    },
    setAttendance(event) {
      return;
      let self = this;
      clearInterval(this.attendance_interval);

      Api.post(
        "/event-duration/store",
        { event: event },
        util.getAuthConfig()
      ).then((res) => {
        let event_duration_id = res.data.event_duration_id;
        self.attendance_interval = setInterval(function () {
          Api.get(
            `/event-duration/update?event_duration_id=${event_duration_id}`,
            util.getAuthConfig()
          ).then((res) => {});
        }, 10000);
      });
    },

    logClickActivity(url) {
      util.logActivity(this, this.selected_booth, `content ${url}`);

      // util.logActivity(this, this.selected_booth, `content ${url}`);
    },
    openVideo(src) {
      console.log("src", src);
      window.open(src, "_blank");
    },
    openPdf(src) {
      util.logActivity(this, this.selected_booth, `pdf ${src}`);

      axios(src, {
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        })
        .catch((error) => {
          // console.log(error);
        });
    },
    openOrganizersModal() {
      $("#organizersModal").modal("toggle");
    },
    openLity(url) {
      this.setAttendance(url);
      util.logActivity(this, this.selected_booth, `content ${url}`);

      var instance = lity(url);
      var opener = instance.opener();
    },
    openContentModal(booth) {
      this.setAttendance(booth);
      util.logActivity(this, booth, `clicked`);

      this.selected_booth = booth;

      $("#contentModal").modal("toggle");
    },
    reroute(path) {
      if (path == "/auditorium") {
        if (!this.settings.is_aud_open) {
          $("#closedAuditorium").modal("show");
          return;
        }
      }
      this.$router.push(path);
    },
    openURL(link) {
      window.open(link);
    },
  },
  mounted() {
    (async () => {
      const getData = await localStorage.getItem("accesscode");
      if (!getData) {
        setTimeout(() => {
          $("#AccessCodeModal").modal("show");
        }, 500);
      } else if (getData == "DEIWEEK2024") {
        this.accessCode = getData;
      } else {
        setTimeout(() => {
          $("#AccessCodeModal").modal("show");
        }, 500);
      }
    })().catch((err) => {
      console.error(err);
      setTimeout(() => {
        $("#AccessCodeModal").modal("show");
      }, 500);
    });

    ImageMap("img[usemap]", 300);

    function resize() {
      var windowHeight = $(".bgimg").height();
      var imageWidth = $(".bgimg").width();
      $("#thelma").css("position", "absolute");
      $("#karla").css("position", "absolute");
      $("#rachael").css("position", "absolute");
      $("#cecil").css("position", "absolute");

      $("#thelma").css("display", "none");
      $("#karla").css("display", "none");
      $("#rachael").css("display", "none");
      $("#cecil").css("display", "none");

      $("#karla").css("top", 0.32 * windowHeight);
      $("#karla").css("left", 0.3 * imageWidth);

      $("#rachael").css("top", 0.34 * windowHeight);
      $("#rachael").css("left", 0.4 * imageWidth);

      $("#cecil").css("top", 0.35 * windowHeight);
      $("#cecil").css("left", 0.47 * imageWidth);

      $("#thelma").css("top", 0.31 * windowHeight);
      $("#thelma").css("left", 0.25 * imageWidth);

      $("#cursor_america_aud").css("top", 0.455 * windowHeight);
      $("#cursor_america_aud").css("left", 0.115 * imageWidth);

      $("#cursor_apac_aud").css("top", 0.49 * windowHeight);
      $("#cursor_apac_aud").css("left", 0.1 * imageWidth);

      $("#cursor_emea_aud").css("top", 0.54 * windowHeight);
      $("#cursor_emea_aud").css("left", 0.095 * imageWidth);

      $("#cursor_messagewall").css("top", 0.29 * windowHeight);
      $("#cursor_messagewall").css("left", 0.73 * imageWidth);

      $("#cursor_aa").css("top", 0.55 * windowHeight);
      $("#cursor_aa").css("left", 0.3 * imageWidth);

      $("#cursor_ad").css("top", 0.55 * windowHeight);
      $("#cursor_ad").css("left", 0.4 * imageWidth);

      $("#cursor_aspire").css("top", 0.55 * windowHeight);
      $("#cursor_aspire").css("left", 0.48 * imageWidth);

      $("#cursor_bold").css("top", 0.55 * windowHeight);
      $("#cursor_bold").css("left", 0.56 * imageWidth);

      $("#cursor_next").css("top", 0.55 * windowHeight);
      $("#cursor_next").css("left", 0.65 * imageWidth);

      $("#cursor_pride").css("top", 0.55 * windowHeight);
      $("#cursor_pride").css("left", 0.765 * imageWidth);

      $("#cursor_sage").css("top", 0.81 * windowHeight);
      $("#cursor_sage").css("left", 0.57 * imageWidth);

      $("#cursor_vets").css("top", 0.82 * windowHeight);
      $("#cursor_vets").css("left", 0.66 * imageWidth);

      $("#cursor_wfn").css("top", 0.84 * windowHeight);
      $("#cursor_wfn").css("left", 0.77 * imageWidth);

      $("#cursor_wotm").css("top", 0.86 * windowHeight);
      $("#cursor_wotm").css("left", 0.9 * imageWidth);

      $("#cursor_out").css("top", 0.82 * windowHeight);
      $("#cursor_out").css("left", 0.51 * imageWidth);
    }

    let self = this;
    $("#contentModal").on("hidden.bs.modal", function () {
      self.selected_booth = "";
      clearInterval(self.attendance_interval);
    });

    $(".lityLink").on("click", function () {
      self.setAttendance("photo-booth");
      util.logActivity(self, "photo-booth", `visit`);
      var initLity = lity($(this).data("target"));
      $(".lity-container").css("width", "100%");
    });

    $(document).on("lity:close", function (event, instance) {
      clearInterval(self.attendance_interval);
    });
    $(window).on("load", function () {
      resize();
    });
    $(window).resize(function () {
      resize();
    });
    $("#AccessCodeModal").on("hide.bs.modal", function (event) {
      if (self.accessCode != "DEIWEEK2024") {
        event.preventDefault();
      }
    });
    resize();
  },
};
</script>

<style lang="scss" scoped>
.lobby {
  background-color: #d4cebc;
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  & img {
    height: 100%;
    width: 100%;
  }

  area {
    cursor: pointer;
  }

  .clickImage {
    position: absolute;
    z-index: 100;
    width: 36px;
    height: auto;
    cursor: pointer;
  }

  #contentModal {
    color: #503628;

    .modal-body {
      overflow-x: hidden;
    }

    p {
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    & .pdf {
      & img {
        object-fit: cover;
        object-position: left;
      }
    }

    .video,
    .pdf,
    .quiz {
      cursor: pointer;
    }
  }
}
</style>
